import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import "antd/dist/antd.css";
import secureLocalStorage from "react-secure-storage";
import { GlobalStateProvider } from "./utils/GlobalStateContext";
import { msalConfig } from "./utils/msalConfig";
import { MsalProvider } from "@azure/msal-react";
import { BrowserRouter } from "react-router-dom";
import TokenRefresher from "./utils/TokenRefresher";
const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
};
const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    msalInstance.setActiveAccount(event.payload.account);
    secureLocalStorage.setItem("lIU", event.payload.account.name);
    secureLocalStorage.setItem("accessToken", event.payload.accessToken);

    // Check if refresh token is present
    if (event.payload.refreshToken) {
      // Store refresh token and its expiration time
      secureLocalStorage.setItem("refreshToken", event.payload.refreshToken);
      secureLocalStorage.setItem(
        "refreshTokenExpiry",
        event.payload.refreshTokenExpiresOn.getTime()
      );
    } else {
    }
  } else {
    return false;
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MsalProvider instance={msalInstance}>
    <BrowserRouter>
      <AlertProvider template={AlertTemplate} {...options}>
        <GlobalStateProvider>
          <App msalInstance={msalInstance} />
          {secureLocalStorage.getItem("accessToken") && <TokenRefresher />}
        </GlobalStateProvider>
      </AlertProvider>
    </BrowserRouter>
  </MsalProvider>
);

reportWebVitals();
