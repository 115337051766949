import React from 'react';

const NotFound = () => {
  return <div className="center-container">
  <h1 className="centered-text">Page not found</h1>
</div>;

};

export default NotFound;
