import React, { useState, useEffect, useContext } from "react";
import { Divider, Table, Tabs } from "antd";
import { useLocation } from "react-router-dom";
import APICaller from "../../../utils/APICaller";
import "./forms.css";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import { GlobalStateContext } from "../../../utils/GlobalStateContext";
import BeforeTableHeader from "../../layouts/beforeTableHeader";
import { JsonToExcel } from "react-json-to-excel";
import HandleSearch from "../../../utils/CommonSearchComponent";
import { exportJson } from "../../../utils/export";

// Destructure Tabs components from Ant Design
const { TabPane } = Tabs;
const SendForms = ({ children }) => {
  const location = useLocation();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);

  const [showLoader, setShowLoader] = useState(true);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [tab, setTab] = useState("1");

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedRowsArray, setSelectedRowsArray] = useState([]);

  {
    /*Defining columns for Voc - Forms Listing*/
  }
  const columns = [
    {
      title: () => {
        return <div className="tableTitle"> ID</div>;
      },
      dataIndex: "id",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Form Name</div>;
      },
      dataIndex: "formDisplayName",
      render: (text, row) => {
        return <span className="rowstyle">{row && row?.formDisplayName}</span>;
      },
    },
    ,
    {
      title: () => {
        return <div className="tableTitle">Recipient</div>;
      },
      dataIndex: "recipient",
      render: (text, row) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Status</div>;
      },
      dataIndex: "status",
      render: (text) => <span className="rowstyle">{text}</span>,
    },

    {
      title: () => {
        return (
          <div className="tableTitle">
            {tab === "1" ? "Created On" : "Captured On"}
          </div>
        );
      },
      dataIndex: tab === "1" ? "created_at" : "updated_at",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
  ];

  /*begin::getSendFormsList will fetch list of SendForms*/
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  {
    /*Export functionality on VOC Form Listing's table clicking on checkboxes*/
  }
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      const exportColumns = [
        { key: "Form Name", path: "formName.formDisplayName" },
        {
          key: "Recipients",
          path: "recipients",
          formatter: (value) => (value ? value.toString() : ""),
        },
        { key: "Status", path: "status" },
      ];

      // Depending on the value of 'tab', add additional columns
      if (tab === "1") {
        exportColumns.push({
          key: "Created At",
          path: "createdAt",
          formatter: (value) =>
            value ? moment(value).format("YYYY-MM-DD HH:mm A") : "",
        });
      } else if (tab === "2") {
        exportColumns.push({
          key: "Captured On",
          path: "updatedAt",
          formatter: (value) =>
            value ? moment(value).format("YYYY-MM-DD HH:mm A") : "",
        });
      }
      exportJson(
        selectedRows,
        globalState?.formLists,
        "Forms",
        exportColumns,
        setGlobalState,
        globalState
      );

      /*const selectedRowKeysChildInfoArr = [];

      Object.keys(selectedRows).map((key) => {
        selectedRowKeysChildInfoArr.push(selectedRows?.[key]?.id);
      });

      const formLists = globalState?.formLists;
      const forms = [];
      if (formLists.hasOwnProperty("response")) {
        const allFormArray = formLists.response;
        allFormArray.map((formLists) => {
          if (selectedRowKeysChildInfoArr.includes(formLists?._id)) {
            const formDisplayName = formLists?.formName?.formDisplayName;
            const recipient = formLists?.recipients.toString();
            const status = formLists?.status;
            const createdAt = formLists?.createdAt;
            const capturedOn = formLists?.updatedAt;
            let form = {
              "Form Name": formDisplayName,
              Recipients: recipient,
              Status: status,
            };

            if (tab === "1") {
              form["Created At"] =
                createdAt && moment(createdAt).format("YYYY-MM-DD");
            }
            if (tab === "2") {
              form["Captured On"] =
                capturedOn && moment(capturedOn).format("YYYY-MM-DD");
            }
            forms.push(form);
          }
        });
      } else {
        console.log("Issue in fetching the data");
      }

      setGlobalState({
        ...globalState,
        exportFormsJson: forms,
        selectedRowKeysChildInfo: selectedRowKeysChildInfoArr,
      });*/
    },
  };

  {
    /*VForm Complete and Delete action*/
  }
  const handleAuditAction = (params) => {
    switch (params) {
      case "complete":
        APICaller(
          secureLocalStorage.getItem("role"),
          secureLocalStorage.getItem("accessToken"),
          "/forms/updateMul",
          "POST",
          {
            role: secureLocalStorage.getItem("role"),
            ids: globalState.selectedRowKeysChildInfo,
          }
        )
          .then((response) => {
            getSendFormsList();
            setSelectedRowKeys([]);
          })
          .catch((error) => {});
        break;
      case "delete":
        let submitDeleteVFormModal = "";
        if (isDeleteModalVisible === true) {
          submitDeleteVFormModal = "clickedDeleteVFormOK";
        }
        if (submitDeleteVFormModal === "clickedDeleteVFormOK") {
          setShowLoader(true);
          APICaller(
            secureLocalStorage.getItem("role"),
            secureLocalStorage.getItem("accessToken"),
            "/forms/deleteMul",
            "POST",
            {
              role: secureLocalStorage.getItem("role"),
              ids: globalState.selectedRowKeysChildInfo,
            }
          )
            .then((response) => {
              setShowLoader(false);
              setIsDeleteModalVisible(false);
              getSendFormsList();
              setSelectedRowKeys([]);
            })
            .catch((error) => {});
        } else {
          setIsDeleteModalVisible(true);
        }
        break;
    }
  };

  const handleTabChange = (key) => {
    setTab(key);
    secureLocalStorage.setItem("tab", key);
    getSendFormsList("", "", key);
  };

  const getSendFormsList = async (source = "", pagination = "", key = "") => {
    setShowLoader(true);
    let hasSearchedValue, searchKeyword;
    if (secureLocalStorage.getItem("hasSearchedValue")) {
      hasSearchedValue = "yes";
      searchKeyword = secureLocalStorage.getItem("searchKeyword");
    } else {
      hasSearchedValue = "";
      searchKeyword = "";
    }
    const searchIn = location.pathname.replace("/", "");
    const callActionFrom = source ? source : "";
    const globalStates = {
      globalState: globalState,
      setGlobalState: setGlobalState,
    };
    const { current, pageSize } = pagination;
    setSelectedRowKeys([]);
    const allStates = {
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      selectedRowsArray: selectedRowsArray,
      setSelectedRowsArray: setSelectedRowsArray,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 50 },
      setPagination: setPagination,
      list: list,
      setList: setList,
      tab: key
        ? key
        : secureLocalStorage.getItem("tab")
        ? secureLocalStorage.getItem("tab")
        : 1,
    };

    const allRecs = await HandleSearch(
      hasSearchedValue,
      "sendForms",
      searchKeyword,
      callActionFrom,
      globalStates,
      allStates
    );
  };

  useEffect(() => {
    getSendFormsList("", "", "1");
  }, []);

  const tabChildern = tab && (
    <>
      <div className="count-inner vformsTab">
        <span>{globalState?.recordCount + " Forms Found"}</span>
      </div>
      {/*Export and Delete buttons*/}
      <div className="right-buttons">
        {(secureLocalStorage.getItem("role") === "admin" ||
          secureLocalStorage.getItem("role") === "auditor" ||
          secureLocalStorage.getItem("role") === "qc") && (
          <>
            {globalState.selectedRowKeysChildInfo &&
              globalState.selectedRowKeysChildInfo.length > 0 && (
                <JsonToExcel
                  title="Export as Excel"
                  data={globalState.exportFormsJson}
                  fileName="form"
                  btnClassName="table-buttons"
                />
              )}
          </>
        )}
      </div>
      <Divider />
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={globalState.list}
        pagination={globalState?.pagination}
        onChange={(e) => {
          getSendFormsList("pagination", e, "");
        }}
      />
    </>
  );
  return (
    <>
      <div className="all-content">
        <BeforeTableHeader list={list && list.length} href="/forms/add" />
        <div className="all-table-holder">
          {showLoader === true && (
            <div className="customLoader">Loading...</div>
          )}

          <div className="forms-buttons">
            {/* Define individual tabs using TabPane */}
            <Tabs
              defaultActiveKey="1"
              onChange={handleTabChange}
              items={[
                {
                  label: "Created Forms",
                  key: "1",
                  children: tabChildern,
                },
                {
                  label: "Submitted Forms",
                  key: "2",
                  children: tabChildern,
                },
              ]}
              className="w-100"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default SendForms;
