import React from "react";
import { Divider } from "antd";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";

const DeletedAuditDetails = ({
  auditFormInput,
  fatalNotFatalInfo,
  timeStampsDetails,
  qualityScore,
  auditorProfileImage,
  agentProfileImage,
}) => {
  const auditDetailsFormInput = auditFormInput && auditFormInput;
  return (
    <>
      <span className="text11 audit-details-left">Audit Details</span>

      {auditDetailsFormInput &&
        Object.entries(auditDetailsFormInput).map(([key, value], i) => (
          <div
            className="align-items-center d-flex justify-content-between mt-4"
            key={i}
          >
            {key === "Reason for duplicate Transaction ID" && value === "" ? (
              <></>
            ) : (
              <>
                <span className="text11 audit-details-left color-grey">
                  {key && key.charAt(0)?.toUpperCase() + key.slice(1)}
                </span>
                <span>
                  {key === "Stage" ? (
                    <img
                      src={
                        value === "Audit Completed"
                          ? "../audited.PNG"
                          : value === "Audit Pending"
                          ? "../pending.png"
                          : value === "Accepted"
                          ? "../completed.png"
                          : "../disputed.png"
                      }
                      style={{ width: "17px" }}
                      alt="logo"
                    />
                  ) : key === "Auditor" ? (
                    <img
                      className="auditProfileImage"
                      src={
                        auditorProfileImage?.profilePicUrl
                          ? auditorProfileImage?.profilePicUrl
                          : process.env.REACT_APP_BACKEND + "/images/dummy.jpg"
                      }
                      alt={auditorProfileImage?.name}
                    />
                  ) : key === "agent" ? (
                    <img
                      className="auditProfileImage"
                      src={
                        agentProfileImage?.profilePicUrl
                          ? agentProfileImage?.profilePicUrl
                          : process.env.REACT_APP_BACKEND + "/images/dummy.jpg"
                      }
                      alt={agentProfileImage?.name}
                    />
                  ) : (
                    ""
                  )}

                  <span className="text11 audit-details-left">
                    {key === "Audit Score"
                      ? secureLocalStorage.getItem("auditScore")
                        ? secureLocalStorage.getItem("auditScore") + "%"
                        : qualityScore + "%"
                      : key === "Transaction Date"
                      ? moment(value).format("DD-MM-YY")
                      : key === "Audit Date and Time"
                      ? moment(value).format("DD-MM-YY HH:MM A")
                      : value &&
                        value?.charAt(0)?.toUpperCase() + value.slice(1)}
                  </span>
                </span>
              </>
            )}
          </div>
        ))}

      {fatalNotFatalInfo &&
        Object.entries(fatalNotFatalInfo).map(([key, value], i) => (
          <div
            className="align-items-center d-flex justify-content-between mt-4"
            key={i}
          >
            <span className="text11 audit-details-left color-grey">
              {key === "notFatal"
                ? "Not Fatal"
                : key && key.charAt(0).toUpperCase() + key.slice(1)}
            </span>

            <span className="text11 audit-details-left">{value && value}</span>
          </div>
        ))}
      <Divider />
      {timeStampsDetails && (
        <span className="text11 audit-details-left">Time Stamps</span>
      )}
      {timeStampsDetails &&
        Object.entries(timeStampsDetails).map(([key, value], i) =>
          value !== null ? (
            <div
              className="align-items-center d-flex justify-content-between mt-4"
              key={i}
            >
              <span className="text11 audit-details-left color-grey">
                {key &&
                  key.charAt(0).toUpperCase() + key.slice(1).replace("A", " A")}
              </span>
              <span>
                <span className="text11 audit-details-left">
                  {moment(value).format("DD-MM-YY HH:mm A")}
                </span>
              </span>
            </div>
          ) : (
            ""
          )
        )}
    </>
  );
};

export default DeletedAuditDetails;
