import { Button, Col, Form, Input, Row, Select, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useContext, useState } from "react";
import BeforeTableHeader from "../../layouts/beforeTableHeader";
import APICaller from "../../../utils/APICaller";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import { GlobalStateContext } from "../../../utils/GlobalStateContext";
import { useForm } from "antd/lib/form/Form";
import { useNavigate } from "react-router-dom";

const AddForm = () => {
  const navigate = useNavigate();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [showLoader, setShowLoader] = useState(true);
  const [form] = useForm();
  const [list, setList] = useState([]);
  const [errors, setErrors] = useState([]);

  const lIUId = secureLocalStorage.getItem("lIUId");

  const { Option } = Select;
  function isValidJSON(str) {
    try {
      JSON.parse(str);     
      return true;
    } catch (error) {
      return false;
    }
  }
  const onFinish = (values) => {
    const jsonString = values?.formContent;
    if (!isValidJSON(jsonString)) {
      message.error("Content is not valid json");
    } else {
      APICaller(
        secureLocalStorage.getItem("role"),
        secureLocalStorage.getItem("accessToken"),
        "/forms",
        "POST",
        {
          source: "form",
          formName: values?.formName.replaceAll(" ", "-"),
          formDisplayName: values?.formDisplayName,
          formType: values?.formType ? values?.formType : "feedback",
          formContent: values?.formContent,
          formDefaultReplyTo: values?.formDefaultReplyTo,
          formDefaultSender: values?.formDefaultSender,
          formDefaultWatchers: values?.formDefaultWatchers,
          status: "ACTIVE",
          createdBy: lIUId,
          createdAt: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 19)
            .replace("T", " "),
        }
      )
      .then((response) => {
        setErrors([]);
        message.success("Form created successfully");
        // Reset form fields
        form.resetFields();
        navigate("/forms");
      })
      .catch((error) => {
        if (error.data && error.data.message) {
          setErrors(error.data.message);
        } else {
          message.error("An error occurred while creating the Form");
        }
      });
    }
  };

  /*begin::getVFormList will fetch list of forms*/
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const getVFormsList = async (page = 1, pageSize = 10) => {
    try {
      setGlobalState({ ...globalState, list: [] });
      const response = await APICaller(
        secureLocalStorage.getItem("role"),
        secureLocalStorage.getItem("accessToken"),
        "/forms/findAll",
        "POST",
        { page, pageSize }
      );
      const { forms, totalCount } = response;
      const temp = forms.map((dataList, index) => ({
        key: dataList._id,
        id: index + 1,
        name: dataList.name,
        permission: dataList.permission,
        created_at: moment(dataList.createdAt).format("DD-MM-YY HH:mm A"),
      }));
      setList(temp);
      setGlobalState({ ...globalState, list: temp });
      setShowLoader(false);
      setPagination({
        ...pagination,
        current: page,
        pageSize,
        total: totalCount,
      });
    } catch (error) {
      console.error("Error fetching forms list:", error);
    }
  };
  /*end::Add new form*/
  {
    /*For Watchers dropdown values in state*/
  }
  const childrenList = [];
  const [selectedValues, setSelectedValues] = useState([]);
  const handleChange = (value) => {
    setSelectedValues(value);
  };
  return (
    <div className="all-content">
      <BeforeTableHeader list={list && list.length} href="" />
      <div className="all-table-holder border-0">
        <Form
          name="basic"
          onFinish={onFinish}
          initialValues={{ remember: true }}
          layout="vertical"
          id="antdformstyle"
          form={form}
        >
          <Row gutter={[12, 12]}>
            <Col sm={12}>
              <Form.Item
                className="m-0"
                label="Name"
                name="formName"
                rules={[
                  { required: true, message: "Please enter the form name!" },
                ]}
              >
                <Input className="w-100 p-2 rounded-2" />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                className="m-0"
                label="Display Name"
                name="formDisplayName"
                rules={[
                  {
                    required: true,
                    message: "Please enter the form display name!",
                  },
                ]}
              >
                <Input className="w-100 p-2 rounded-2" />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                className="m-0"
                label="Type"
                name="formType"
                rules={[
                  {
                    required: true,
                    message: "Please enter the form type!",
                  },
                ]}
              >
                <Input className="w-100 p-2 rounded-2" />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                className="m-0"
                label="Reply To"
                name="formDefaultReplyTo"
                rules={[
                  {
                    required: true,
                    message: "Please enter the form default reply to!",
                  },
                ]}
              >
                <Input className="w-100 p-2 rounded-2" />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                className="m-0"
                label="Sender"
                name="formDefaultSender"
                rules={[
                  {
                    required: true,
                    message: "Please enter the form default sender!",
                  },
                ]}
              >
                <Input className="w-100 p-2 rounded-2" />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                className="m-0"
                label="Watchers"
                name="formDefaultWatchers"
                rules={[
                  {
                    required: true,
                    message: "Please enter the form default watchers!",
                  },
                ]}
              >
                <Select
                  mode="tags"
                  size={"large"}
                  placeholder="Enter Email ID's"
                  value={selectedValues}
                  onChange={handleChange}
                >
                  {childrenList}
                </Select>
              </Form.Item>
            </Col>

            <Col sm={24}>
              <Form.Item
                className="m-0"
                label="Content"
                name="formContent"
                rules={[
                  {
                    required: true,
                    message: "Please enter the form content!",
                  },
                ]}
                validateStatus={
                  errors && errors["formContent"] ? "error" : undefined
                }
                help={
                  errors && errors["formContent"]
                    ? errors["formContent"]
                    : undefined
                }
              >
                <TextArea className="w-100 p-2 rounded-2" />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                label="Status"
                name="status"
                rules={[{ required: true, message: "Please select a status!" }]}
              >
                <Select>
                  <Option value="ACTIVE">ACTIVE</Option>
                  <Option value="INACTIVE">IN-ACTIVE</Option>
                </Select>
              </Form.Item>
            </Col>
            <div className="d-flex justify-content-center mt-4 w-100">
              <Button
                type="primary"
                htmlType="reset"
                className="submit-btn border-0 me-4 rounded"
              >
                Clear
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="submit-btn border-0 me-0 rounded"
              >
                Submit
              </Button>
            </div>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default AddForm;
