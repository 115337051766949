import React, { useEffect, useState } from "react";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import "./survey.css";
import APICaller from "../../../utils/APICaller";

function SurveyComponent({ surveyJSON, id, source = "" }) {
  // Function to handle survey completion
  const onSurveyComplete = (survey, options) => {
    setSurveyResult(survey.data);
    APICaller("", "", `/feedback-requests/survey/${id}`, "PUT", {
      surveyData: survey.data,
      dateTime: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .slice(0, 19)
        .replace("T", " "),
    }).then((response) => {});

    // Handle survey completion
  };

  // Function to handle survey value changes
  const onSurveyValueChanged = (sender, options) => {
    // Handle survey value changes
  };

  // Initialize state to hold survey results
  const [surveyResult, setSurveyResult] = useState(null);
  const [surveyQuestionsCount, setSurveyQuestionsCount] = useState(0);

  const generateSurveyJSON = () => {
    const survey = {
      questions: [],
    };

    if (surveyJSON) {
      // Check if meta object exists in surveyJSON
      if (surveyJSON && surveyJSON.meta) {
        // Add completedHtml from surveyJSON meta to survey meta
        survey.meta = {
          completedHtml: surveyJSON.meta.completed_html,
        };
      }
      if (
        surveyJSON &&
        surveyJSON.questions &&
        surveyJSON.questions.length > 0
      ) {
        surveyJSON?.questions.forEach((question) => {
          const surveyQuestion = {
            type: question.input_type,
            name: question.qkey,
            title: question.display_text,
            isRequired: question.meta.required,
            defaultValue: question.defaultValue,
          };

          if (question.input_type === "radiogroup") {
            surveyQuestion.choices = question.options;
          }

          survey.questions.push(surveyQuestion);

          if (
            question.meta.sub_questions &&
            question.meta.sub_questions.length > 0
          ) {
            question.meta.sub_questions.forEach((subQuestion) => {
              const surveySubQuestion = {
                name: subQuestion.qkey,
                type: subQuestion.input_type,
                title: subQuestion.display_text,
                isRequired: subQuestion.required,
                visibleIf: subQuestion.visible_if,
                defaultValue: subQuestion.defaultValue, // Set default value
              };

              survey.questions.push(surveySubQuestion);
            });
          }
        });
      }
    }
    return survey;
  };
  useEffect(() => {
    if (surveyJSON && surveyJSON.questions && surveyJSON.questions.length > 0) {
      setSurveyQuestionsCount(surveyJSON?.questions.length);
    }
  }, [surveyJSON]);
  return (
    <div className={surveyQuestionsCount}>
      <Survey.Survey
        json={generateSurveyJSON()}
        showCompleteButton={source === "preview" ? false : true} // Set to false to hide the complete button
        showCompletedPage={true} // Set to true to show a completion page
        onComplete={onSurveyComplete} // Pass the onComplete function
        onValueChanged={onSurveyValueChanged} // Pass the onValueChanged function
        //mode="display"
        //firstPageIsStarted={true}
      />
    </div>
  );
}

export default SurveyComponent;
