import React, { useState, useEffect } from "react";
import { Form, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import APICaller from "../../../utils/APICaller";
import secureLocalStorage from "react-secure-storage";

import "../../../App.css";
import SurveyComponent from "../survey/SurveyComponent.jsx";

const FeedbackRequestPreview = ({ children }) => {
  console.log(secureLocalStorage.getItem("role"));
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(true);
  const [feedbackForm, setFeedbackForm] = useState({});
  const [form] = Form.useForm();
  const location = useLocation();

  /*begin::getFeedbackRequestsList will fetch list of users*/
  const id = location.pathname.split("/")[3];
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const getFormDetail = () => {
    const allKeys = Object.keys(localStorage);
    // Loop through the keys and remove items that are not the specific item
    let exceptItems = [
      "@secure.s.lIU",
      "@secure.s.lIUId",
      "@secure.s.role",
      "@secure.s.accessToken",
      "@secure.s.accessTokenExpiry",
      "@secure.s.refreshToken",
      "@secure.s.refreshTokenExpiry",
    ];
    let removeItems = [
      "@secure.s.isSubMenuActive",
      "@secure.j.auditFormInput",
      "@secure.s.auditFormCall",
      "@secure.n.totalAuditedCount",
      "@secure.j.parameterSelectedList",
      "@secure.n.auditedQuestionScoreCount",
      "@secure.n.auditedQuestionCount",
      "@secure.j.countMap",
      "@secure.s.clickedLink",
      "@secure.s.auditScore",
      "@secure.j.timeStampsDetails",
      "@secure.n.weightageFlag",
      "@secure.j.updatedQMF",
      "@secure.s.savedAuditId",
      "@secure.j.savePayloadRequestParams",
    ];
    allKeys.forEach((key) => {
      if (removeItems.includes(key)) {
        localStorage.removeItem(key);
      }
    });

    APICaller(
      secureLocalStorage.getItem("role").toUpperCase(),
      secureLocalStorage.getItem("accessToken"),
      `/feedback-requests/preview/${id}`,
      "GET"
    )
      .then((response) => {
        let parsedFeedbackForm = JSON.parse(response.formContent);
        let questions = parsedFeedbackForm?.elements;
        questions && setFeedbackForm(questions);
        setShowLoader(false);
      })
      .catch((error) => {
        error?.data?.message && message.error(error?.data?.message);
      });
  };
  useEffect(() => {
    getFormDetail();
    document.title = "Customer Satisfaction Survey"; // Set your dynamic title here
  }, []);

  /*end::getFeedbackRequestsList will fetch list of users*/

  const headLine = feedbackForm?.elements?.meta?.headline;

  // Define your survey JSON
  const surveyJSON = feedbackForm && feedbackForm;

  return (
    <div className="single-page surveyCom-container feedBackPreview">
      {showLoader === true && <div className="customLoader">Loading...</div>}
      <div className="backContainer">
        <span className="text11">
          <span
            className="back-btn"
            onClick={() => {
              navigate("/feedback-requests/closed");
            }}
          >
            {/*&#8592; */}
            Back
          </span>
        </span>
      </div>
      {secureLocalStorage.getItem("role") !== "" &&
        (secureLocalStorage.getItem("role") === "admin" ||
          secureLocalStorage.getItem("role") === "qc") && (
          <>
            <div className="surveyCom-outer-container">
              <div className="">
                <span>{headLine}</span>

                {surveyJSON &&
                  surveyJSON.questions &&
                  surveyJSON.questions.length > 0 && (
                    <SurveyComponent
                      surveyJSON={surveyJSON}
                      id={id}
                      source="preview"
                    />
                  )}
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default FeedbackRequestPreview;
