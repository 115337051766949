import React, { useState, useEffect, useContext } from "react";
import { Table } from "antd";
import "./auditforms.css";
import { Link, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { GlobalStateContext } from "../../../utils/GlobalStateContext";
import BeforeTableHeader from "../../layouts/beforeTableHeader";
import HandleSearch from "../../../utils/CommonSearchComponent";

const AuditForms = ({ children }) => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [showLoader, setShowLoader] = useState(true);
  const location = useLocation();
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {},
  };

  const columns = [
    {
      title: () => {
        return <div className="tableTitle"> ID</div>;
      },
      dataIndex: "id",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle"> Form Name</div>;
      },
      dataIndex: "form_name",
      render: (text, row) => (
        <Link
          to={"/auditForms/" + row.key}
          state={{
            route: location.pathname,
            auditFormHasWeightage: row.hasWeightage,
          }}
          style={{ color: "black" }}
          onClick={() => {
            secureLocalStorage.setItem("fromComponent", "auditForms");
          }}
        >
          <span className="rowstyle" style={{ color: "#1875CB" }}>
            {text}
          </span>
        </Link>
      ),
    },
    {
      title: () => {
        return <div className="tableTitle"> Total Audits</div>;
      },
      dataIndex: "total_audits",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle"> Active Audits</div>;
      },
      dataIndex: "active_audits",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle"> Created At</div>;
      },
      dataIndex: "created_at",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
  ];
  const [selectionType, setSelectionType] = useState("checkbox");

  /*begin::getAuditFormList will fetch list of audits*/
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });
  const getAuditFormList = async (source = "", pagination = "") => {
    let hasSearchedValue, searchKeyword;
    if (secureLocalStorage.getItem("hasSearchedValue")) {
      hasSearchedValue = "yes";
      searchKeyword = secureLocalStorage.getItem("searchKeyword");
    } else {
      hasSearchedValue = "";
      searchKeyword = "";
    }
    const searchIn = location.pathname.replace("/", "");
    const callActionFrom = source ? source : "";
    const globalStates = {
      globalState: globalState,
      setGlobalState: setGlobalState,
    };
    const { current, pageSize } = pagination;

    const allStates = {
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 100 },
      setPagination: setPagination,
      list: list,
      setList: setList,
    };

    const allRecs = await HandleSearch(
      hasSearchedValue,
      searchIn,
      searchKeyword,
      callActionFrom,
      globalStates,
      allStates
    );
  };

  const handleTableChange = async (pagination) => {
    const { current, pageSize } = pagination;
    getAuditFormList(current, pageSize);
  };
  useEffect(() => {
    getAuditFormList();
  }, [globalState.isTokenUpdate && globalState.isTokenUpdate === true]);
  /*end::getAuditFormList will fetch list of audits*/

  return (
    <>
      <div className="all-content">
        <BeforeTableHeader
          list={list && list.length}
          showListingCount="yes"
          href={
            secureLocalStorage.getItem("role") === "admin"
              ? "/auditForms/add"
              : ""
          }
        />
        <div className="all-table-holder">
          {showLoader === true && (
            <div className="customLoader">Loading...</div>
          )}

          <Table
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            columns={columns}
            dataSource={globalState?.list}
            pagination={globalState?.pagination}
            onChange={(e) => {
              getAuditFormList("pagination", e);
            }}
          />
        </div>
      </div>
    </>
  );
};
export default AuditForms;
