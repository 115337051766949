import React, { useState, useContext, useEffect } from "react";
import { Button, Col, Form, Input, Row, Select, message } from "antd";
import "./addProcess.css";
import APICaller from "../../../utils/APICaller";
import secureLocalStorage from "react-secure-storage";
import { GlobalStateContext } from "../../../utils/GlobalStateContext";
import { useLocation, useNavigate } from "react-router-dom";
import HandleSearch from "../../../utils/CommonSearchComponent";

const { Option } = Select;

const AddProcess = ({ children }) => {
  const location = useLocation();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [showLoader, setShowLoader] = useState(true);
  const navigate = useNavigate();
  const [departments, setDepartments] = useState([]),
    [errors, setErrors] = useState([]);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    APICaller(
      secureLocalStorage.getItem("role"),
      secureLocalStorage.getItem("accessToken"),
      "/processes",
      "POST",
      {
        name: values?.name,
        department: values?.department,
      }
    )
      .then((response) => {
        setErrors([]);
        message.success("Process created successfully");
        // Reset form fields
        form.resetFields();
        getProcessesList();
      })
      .catch((error) => {
        if (error.data && error.data.message) {
          setErrors(error.data.message);
        } else {
          message.error("An error occurred while creating the process");
        }
      });
  };

  /*begin::getProcessesList will fetch list of users*/
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const getProcessesList = async (source = "", pagination = "") => {
    let hasSearchedValue, searchKeyword;
    if (secureLocalStorage.getItem("hasSearchedValue")) {
      hasSearchedValue = "yes";
      searchKeyword = secureLocalStorage.getItem("searchKeyword");
    } else {
      hasSearchedValue = "";
      searchKeyword = "";
    }
    const searchIn = location.pathname.replace("/", "");
    const callActionFrom = source ? source : "";
    const globalStates = {
      globalState: globalState,
      setGlobalState: setGlobalState,
    };

    const allStates = {
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 50 },
      setPagination: setPagination,
      list: list,
      setList: setList,
    };

    const allRecs = await HandleSearch(
      hasSearchedValue,
      searchIn,
      searchKeyword,
      callActionFrom,
      globalStates,
      allStates
    );
  };

  /*begin::getDepartments will fetch the list of Departments*/
  const getDepartments = () => {
    APICaller(
      secureLocalStorage.getItem("role"),
      secureLocalStorage.getItem("accessToken"),
      "/fetchWithoutPagination",
      "POST",
      { route: "departments", action: "findAll" }
    )
      .then((response) => {
        const temp = [];
        response?.map((department, index) => {
          temp.push({ label: department?.name, value: department?._id });
        });
        setDepartments(temp);
      })
      .catch((error) => {});
  };
  /*end::getDepartments will fetch the list of Departments*/

  useEffect(() => {
    getDepartments();
  }, []);
  return (
    <>
      <div className="all-content addUserCont">
        <div className="all-table-holder">
          <Form
            name="basic"
            onFinish={onFinish}
            initialValues={{ remember: true }}
            layout="vertical"
            id="antdformstyle"
            form={form}
          >
            <Row gutter={[12, 12]}>
              <Col sm={12}>
                <Form.Item
                  className="m-0"
                  label="Name"
                  name="name"
                  rules={[
                    { required: true, message: "Please enter the name!" },
                  ]}
                >
                  <Input className="w-100 p-2 rounded-2" />
                </Form.Item>
              </Col>

              <Col sm={12}>
                <Form.Item
                  label="Department"
                  name="department"
                  rules={[
                    { required: true, message: "Please select a department!" },
                  ]}
                >
                  <Select options={departments} className="" />
                </Form.Item>
              </Col>

              <div className="d-flex justify-content-center w-100">
                <Button
                  type="primary"
                  htmlType="reset"
                  className="submit-btn border-0 me-4 rounded"
                >
                  Clear
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-btn border-0 me-0 rounded"
                >
                  Submit
                </Button>
              </div>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};
export default AddProcess;
