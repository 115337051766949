import { Form, message, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./auditFormView.css";
import {
  ParseElementsIntoFormNew,
  getScore,
  getScoreQuestionCount,
} from "../../../parser";
import APICaller from "../../../utils/APICaller";
import secureLocalStorage from "react-secure-storage";
import BeforeTableHeader from "../../layouts/beforeTableHeader";

const AuditFormView = ({ children = null, key_elem = "1", nameLink = "" }) => {
  const [qualityScore, setQualityScore] = useState(0);
  const location = useLocation();
  const [form] = Form.useForm();
  const [formValue, SetFormValue] = useState({});
  const [QMF, setQMF] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [auditFormHasWeightage, setAuditFormHasWeightage] = useState(
    location?.state?.auditFormHasWeightage
  );
  const maxScore = getScoreQuestionCount(QMF);
  const [scoreQuestionCount, setScoreQuestionCount] = useState(0);
  const [crrScore, setCrrScore] = useState(0);
  const [fatalNotFatal, setFatalNotFatal] = useState({});
  const [fatalCount, setFatalCount] = useState(0);
  const [notFatalCount, setNotFatalCount] = useState(0);
  const id = location.pathname.split("/")[2];

  const [isCritical, setIsCritical] = useState(false);

  // Create an object to store subarrays
  var fatalNotFatalArr = {};
  /*begin::getAuditFormDetail will fetch audit form's detail*/
  useEffect(() => {
    getAuditFormDetail();
  }, [location?.state?.route === "/auditForms"]);

  useEffect(() => {
    if (Number(qualityScore) < 100) {
      // Select all elements with the class 'text123'
      const elementsWithText123 = document.querySelectorAll(
        ".auditFormField .ant-form-item-control-input .ant-form-item-control-input-content"
      );

      // Loop through the selected elements and add an asterisk to each
      elementsWithText123.forEach((element) => {
        const specificChild = element.querySelector(".ant-radio-group");
        const text123Child = element.querySelector(".text123");

        if (!specificChild) {
          // Check if the next element is already an asterisk element
          const nextElement = text123Child?.nextElementSibling;
          if (
            nextElement &&
            nextElement.classList.contains("lessScoreMandatoryFields")
          ) {
            return; // Return early if the asterisk span already exists
          }

          // Create a span element with the asterisk symbol
          const asteriskSpan = document.createElement("span");
          asteriskSpan.innerText = " *";
          asteriskSpan.style.color = "red";
          asteriskSpan.classList.add("lessScoreMandatoryFields"); // Remove the = sign

          text123Child?.parentNode.insertBefore(
            asteriskSpan,
            text123Child.nextSibling
          );
        }
      });
    }
    if (Number(qualityScore) === 100) {
      // Select all spans with the class 'lessScoreMandatoryFields'
      const spansToRemove = document.querySelectorAll(
        ".lessScoreMandatoryFields"
      );

      // Loop through the selected spans and remove them
      spansToRemove.forEach((span) => {
        span.remove();
      });
    }
  }, [qualityScore]);

  const getAuditFormDetail = () => {
    const allKeys = Object.keys(localStorage);
    // Loop through the keys and remove items that are not the specific item
    let exceptItems = [
      "@secure.s.lIU",
      "@secure.s.lIUId",
      "@secure.s.role",
      "@secure.s.accessToken",
      "@secure.s.accessTokenExpiry",
      "@secure.s.refreshToken",
      "@secure.s.refreshTokenExpiry",
    ];
    let removeItems = [
      "@secure.s.aID",
      "@secure.s.isSubMenuActive",
      "@secure.j.auditFormInput",
      "@secure.s.auditFormCall",
      "@secure.n.totalAuditedCount",
      "@secure.j.parameterSelectedList",
      "@secure.n.auditedQuestionScoreCount",
      "@secure.n.auditedQuestionCount",
      "@secure.j.countMap",
      "@secure.s.clickedLink",
      "@secure.s.auditScore",
      "@secure.j.timeStampsDetails",
      "@secure.n.weightageFlag",
      "@secure.j.updatedQMF",
      "@secure.s.savedAuditId",
      "@secure.j.savePayloadRequestParams",
    ];
    allKeys.forEach((key) => {
      if (removeItems.includes(key)) {
        localStorage.removeItem(key);
      }
    });
    APICaller(
      secureLocalStorage.getItem("role"),
      secureLocalStorage.getItem("accessToken"),
      `/auditForms/${id}`,
      "GET"
    )
      .then((response) => {
        secureLocalStorage.removeItem("parameterSelectedList");
        if (secureLocalStorage.getItem("updatedQMF")) {
          setQMF(secureLocalStorage.getItem("updatedQMF"));
        } else {
          // Initialize subarrays
          fatalNotFatalArr["fatal"] = [];
          fatalNotFatalArr["notFatal"] = [];

          var parsedQMF = JSON.parse(response.formContent);
          secureLocalStorage.setItem("totalAuditedCount", 0);
          let questions = parsedQMF?.elements?.questions;

          let count = 1;
          let totalWeightage = 0;

          for (let i = 0; i < questions?.length; i++) {
            if (questions[i].input_type === "radio-group") {
              if (questions[i].meta?.critical === true) {
                if (questions[i].qkey) {
                  fatalNotFatalArr["fatal"].push(questions[i].qkey);
                }
              } else {
                if (questions[i].qkey) {
                  fatalNotFatalArr["notFatal"].push(questions[i].qkey);
                }
              }

              setScoreQuestionCount(count);
              secureLocalStorage.setItem("totalAuditedCount", count++);
            }
            if (questions[i].meta.weightage) {
              setAuditFormHasWeightage("TRUE");
            }
          }
          setFatalNotFatal(fatalNotFatalArr);
          setQMF(parsedQMF);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        error?.data?.message && message.error(error?.data?.message);
      });
  };
  /*end::getAuditFormDetail will fetch audit form's detail*/

  /*begin::getValue will set the value of Audit Form's fields*/
  const getValue = (value, id, isQualityMetric, weightage, serial_no) => {
    if (
      fatalNotFatal &&
      fatalNotFatal["fatal"] &&
      fatalNotFatal["fatal"].includes(id)
    ) {
      if (value === "No") {
        if (fatalCount !== 0) {
          setFatalCount(fatalCount + 1);
        } else {
          setFatalCount(1);
        }
      } else {
        if (fatalCount) {
          setFatalCount(fatalCount - 1);
        }
      }
    }
    if (
      fatalNotFatal &&
      fatalNotFatal["notFatal"] &&
      fatalNotFatal["notFatal"].includes(id)
    ) {
      if (value === "No") {
        if (notFatalCount !== 0) {
          setNotFatalCount(notFatalCount + 1);
        } else {
          setNotFatalCount(1);
        }
      } else {
        if (notFatalCount) {
          setNotFatalCount(notFatalCount - 1);
        }
      }
    }

    /*begin::Set array in the way user audited the parameters of the Audit Form*/
    if (value === "Yes" || value === "No" || value === "Not Applicable") {
      if (secureLocalStorage.getItem("parameterSelectedList")) {
        let parameterSelectedList = secureLocalStorage.getItem(
          "parameterSelectedList"
        );

        if (!parameterSelectedList.includes(id)) {
          parameterSelectedList.push(id);
        } else {
          parameterSelectedList = parameterSelectedList.filter((x) => x !== id);
          parameterSelectedList.push(id);
        }
        secureLocalStorage.setItem(
          "parameterSelectedList",
          parameterSelectedList
        );
      } else {
        secureLocalStorage.setItem("parameterSelectedList", [id]);
      }
    }
    /*end::Set array in the way user audited the parameters of the Audit Form*/

    var questions = QMF?.elements?.questions;

    let totalWeightage = 0; //Variable to store overall weightage of audit form having parameter's weightage
    let countQA = []; //Variable to store all user audited parameter's value
    let isCriticalParameterHasNo = false;
    for (let i = 0; i < questions?.length; i++) {
      if (questions[i]?.qkey === id) {
        questions[i].selectedValue = value; //This is setting value in new property selectedValue
      }

      //Setting variable flag if it is critical parameter
      if (
        questions[i]?.meta?.required === true &&
        questions[i].selectedValue === "No"
      ) {
        isCriticalParameterHasNo = true;
      }

      if (questions[i].meta.weightage) {
        totalWeightage = totalWeightage + questions[i].meta.weightage;
      }

      switch (questions[i].selectedValue) {
        case "Yes":
          countQA.push("Yes");
          break;
        case "No":
          countQA.push("No");
          break;
        case "Not Applicable":
          countQA.push("NotApplicable");
          break;
        default:
          break;
      }
    }

    let countMap = {};
    countQA.forEach(function (i) {
      countMap[i] = (countMap[i] || 0) + 1;
    });

    if (countMap.NotApplicable) {
      setCrrScore(countQA && countQA.length - countMap.NotApplicable);
      setScoreQuestionCount(maxScore - countMap.NotApplicable);
    } else {
      if (countMap.Yes) {
        setCrrScore(countQA && countQA.length);
        setScoreQuestionCount(maxScore);
      }
    }
    setIsCritical(isCriticalParameterHasNo);
    if (auditFormHasWeightage === "FALSE" && countMap.No) {
      setCrrScore(countMap && countMap.Yes);
    }
    SetFormValue({
      ...formValue,
      [id]: [
        value,
        isQualityMetric,
        weightage,
        totalWeightage,
        serial_no,
        isCriticalParameterHasNo,
      ],
    });
  };
  /*end::getValue will set the value of Audit Form's fields*/

  /*begin::setQualityScore will set the value of Audit Form's score*/
  useEffect(() => {
    setQualityScore(
      getScore(
        formValue,
        scoreQuestionCount,
        auditFormHasWeightage,
        crrScore,
        isCritical
      )
    );
  }, [formValue]);

  /*end::getValue will set the value of Audit Form's fields*/

  const headLine = QMF?.elements?.meta?.headline;
  const singlePageContent = { headLine };
  return (
    <div className="single-page">
      {showLoader === true && <div className="customLoader">Loading...</div>}
      <BeforeTableHeader
        singlePage="auditFormView"
        singlePageContent={singlePageContent}
        list=""
        href=""
      />

      <div className="container1 inner-single-page auditFormsDetailsRS">
        <div className="scrollbar" id="style-4">
          <span className="text11">Audit Form</span>
          <Divider />
          <Form
            id="myform"
            form={form}
            layout="vertical"
            autoComplete="off"
            scrollToFirstError
          >
            {QMF && ParseElementsIntoFormNew(QMF, getValue)}
          </Form>
        </div>
      </div>

      <div className="p-3 bottom-users">
        <img src="../Ellipse 9.png" alt="logo" />
        <span>
          Audited&nbsp;&nbsp;{crrScore}/{scoreQuestionCount}&nbsp;&nbsp;Score:{" "}
          {qualityScore}%
        </span>
      </div>
    </div>
  );
};
export default AuditFormView;
