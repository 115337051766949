import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  message,
} from "antd";
import "../users/addUser.css";
import APICaller from "../../../utils/APICaller";
import secureLocalStorage from "react-secure-storage";
import { GlobalStateContext } from "../../../utils/GlobalStateContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import HandleSearch from "../../../utils/CommonSearchComponent";

const { Option } = Select;

const AddUser = ({ children }) => {
  const location = useLocation();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [showLoader, setShowLoader] = useState(true);
  const navigate = useNavigate();
  const [clients, setClients] = useState([]),
    [errors, setErrors] = useState([]),
    [reportingSupervisors, setReportingSupervisors] = useState([]),
    [allAtls, setAllAtls] = useState([]),
    [roles, setRoles] = useState([]),
    [selectedRole, setSelectedRole] = useState();
  const [form] = useForm();

  /*begin::getUserList will fetch list of users*/
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });
  const getUserList = async (source = "", pagination = "") => {
    let hasSearchedValue, searchKeyword;
    if (secureLocalStorage.getItem("hasSearchedValue")) {
      hasSearchedValue = "yes";
      searchKeyword = secureLocalStorage.getItem("searchKeyword");
    } else {
      hasSearchedValue = "";
      searchKeyword = "";
    }
    const searchIn = location.pathname.replace("/", "");
    const callActionFrom = source ? source : "";
    const globalStates = {
      globalState: globalState,
      setGlobalState: setGlobalState,
    };

    const allStates = {
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 50 },
      setPagination: setPagination,
      list: list,
      setList: setList,
    };

    const allRecs = await HandleSearch(
      hasSearchedValue,
      searchIn,
      searchKeyword,
      callActionFrom,
      globalStates,
      allStates
    );
  };

  /*begin::getClients will fetch the list of Clients*/
  const getClients = () => {
    APICaller(
      secureLocalStorage.getItem("role"),
      secureLocalStorage.getItem("accessToken"),
      "/fetchWithoutPagination",
      "POST",
      { route: "clients", action: "findAll" }
    )
      .then((response) => {
        const temp = [];
        response?.map((client, index) => {
          temp.push({ label: client?.name, value: client?._id });
        });
        setClients(temp);
      })
      .catch((error) => {});
  };
  /*end::getClients will fetch the list of Clients*/

  /*begin::getRoles will fetch the list of Roles*/
  const getRoles = async () => {
    await APICaller(
      secureLocalStorage.getItem("role"),
      secureLocalStorage.getItem("accessToken"),
      "/roles/fetchWithoutPagination",
      "GET"
    )
      .then((response) => {
        const temp = [];
        response?.map((role, index) => {
          temp.push({ label: role?.name, value: role?.name?.toLowerCase() });
        });
        setRoles(temp);
      })
      .catch((error) => {});
  };
  /*end::getRoles will fetch the list of Roles*/

  /*begin::getReportingSupervisors will fetch the list of ReportingSupervisors*/
  const getReportingSupervisors = () => {
    APICaller(
      secureLocalStorage.getItem("role"),
      secureLocalStorage.getItem("accessToken"),
      "/fetchWithoutPagination",
      "POST",
      { route: "users", action: "findAll", fields: ["tl", "atl"] }
    )
      .then((response) => {
        const storeAllTls = [];
        const getAllTls = response.filter((user) => user?.role === "tl");

        getAllTls?.map((user, index) => {
          storeAllTls.push({
            label: user?.name + " (EmpID: " + user?.empID + ")",
            value: user?._id,
          });
        });
        setReportingSupervisors(storeAllTls);

        const storeAllATls = [];
        const getAllATls = response.filter((user) => user?.role === "atl");

        getAllATls?.map((user, index) => {
          storeAllATls.push({
            label: user?.name + " (EmpID: " + user?.empID + ")",
            value: user?._id,
          });
        });
        setAllAtls(storeAllATls);
      })
      .catch((error) => {});
  };
  /*end::getReportingSupervisors will fetch the list of ReportingSupervisors*/
  /**/
  const [profilePicInfo, setProfilePicInfo] = useState("");
  const [profilePicWithUrlChecked, setProfilePicWithUrlChecked] =
    useState(false);

  const handleCheckboxChange = (e) => {
    setProfilePicWithUrlChecked(e.target.checked);
  };

  const inputComponent = <Input className="w-100 p-2 rounded-2" />;

  const onFinish = (values) => {
    const headers = {
      "Content-Type": "multipart/form-data", // Make sure to set the content type
    };
    const getBase64ImageExtension = (urlOrBase64) => {
      // Check if the input is a Base64 string
      if (urlOrBase64.startsWith("data:image")) {
        // Extract the MIME type from the Base64 string
        const mimeTypeMatch = urlOrBase64.match(
          /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/
        );
        if (mimeTypeMatch && mimeTypeMatch[1]) {
          // Extract the file extension from the MIME type
          const extension = mimeTypeMatch[1].split("/")[1];
          return extension;
        } else {
          // Default to empty string if no extension found
          return "";
        }
      } else {
        // Extract the file extension from the URL
        const ext = urlOrBase64.split(".").pop().split("?")[0];
        return ext;
      }
    };
    APICaller(
      secureLocalStorage.getItem("role"),
      secureLocalStorage.getItem("accessToken"),
      "/users",
      "POST",
      {
        empID: parseInt(values?.empID),
        name: values?.name,
        imageName:
          parseInt(values?.empID) + "_" + values?.name?.replaceAll(" ", "_"),
        email: values?.email,
        urole: values?.urole,
        profilePicUrl:
          profilePicWithUrlChecked === true
            ? values?.profilePicUrl
            : profilePicInfo
            ? profilePicInfo
            : null,
        client: values?.client,
        reportingSupervisor:
          values?.urole === "agent" || values?.urole === "atl"
            ? values?.reportingSupervisor
            : null,
        status: "ACTIVE",
        uploadType: profilePicWithUrlChecked === true ? "thumbUrl" : "other",
        ext:
          profilePicWithUrlChecked === true
            ? getBase64ImageExtension(values?.profilePicUrl)
            : profilePicInfo
            ? getBase64ImageExtension(profilePicInfo)
            : "", //values?.profilePicUrl?.split(".").pop()
      },
      headers
    )
      .then((response) => {
        setErrors([]);
        message.success("User created successfully");
        // Reset form fields
        form.resetFields();
        getUserList();
      })
      .catch((error) => {
        if (error.data && error.data.message) {
          setErrors(error.data.message);
        } else {
          message.error("An error occurred while creating the user");
        }
      });
  };
  useEffect(() => {
    getClients();
    getRoles();
    getReportingSupervisors();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Read image parameters
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        // Check for SVG file type
        const isSVG = file.type === "image/svg+xml";
        img.src && setProfilePicInfo(img.src);
      };
    };

    reader.readAsDataURL(file);
  };
  return (
    <>
      <div className="all-content addUserCont">
        <div className="all-table-holder">
          <Form
            name="basic"
            onFinish={onFinish}
            initialValues={{ remember: true }}
            layout="vertical"
            id="antdformstyle"
            form={form}
          >
            <Row gutter={[12, 12]}>
              <Col sm={12}>
                <Form.Item
                  className="m-0"
                  label="Employee ID"
                  name="empID"
                  rules={[
                    { required: true, message: "Please enter employee id!" },
                  ]}
                  validateStatus={
                    errors && errors["empID"] ? "error" : undefined
                  }
                  help={errors && errors["empID"] ? errors["empID"] : undefined}
                >
                  <InputNumber className="w-100 p-1 rounded-2" />
                </Form.Item>
              </Col>

              <Col sm={12}>
                <Form.Item
                  className="m-0"
                  label="Name"
                  name="name"
                  rules={[
                    { required: true, message: "Please enter the name!" },
                  ]}
                >
                  <Input className="w-100 p-2 rounded-2" />
                </Form.Item>
              </Col>

              <Col sm={12}>
                <Form.Item
                  className="m-0"
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                  validateStatus={
                    errors && errors["email"] ? "error" : undefined
                  }
                  help={errors && errors["email"] ? errors["email"] : undefined}
                >
                  <Input className="w-100 p-2 rounded-2" />
                </Form.Item>
              </Col>

              <Col sm={12}>
                <Form.Item
                  className="m-0"
                  label="Role"
                  name="urole"
                  rules={[{ required: true, message: "Please select a role!" }]}
                >
                  <Select
                    options={roles}
                    className=""
                    onChange={(val) => {
                      setSelectedRole(val);
                    }}
                  ></Select>
                </Form.Item>
              </Col>

              {(selectedRole === "agent" || selectedRole === "atl") && (
                <Col sm={12}>
                  <Form.Item
                    className="m-0"
                    label="Team Leader"
                    name="reportingSupervisor"
                    rules={[
                      {
                        required: true,
                        message: "Please select a team leader!",
                      },
                    ]}
                  >
                    <Select
                      options={reportingSupervisors}
                      className=""
                    ></Select>
                  </Form.Item>
                </Col>
              )}
              {selectedRole === "agent" && (
                <>
                  <Col sm={12}>
                    <Form.Item
                      className="m-0"
                      label="Assistant Team Leader"
                      name="atl"
                      rules={[
                        {
                          required: true,
                          message: "Please select a assistance team leader!",
                        },
                      ]}
                    >
                      <Select options={allAtls} className=""></Select>
                    </Form.Item>
                  </Col>
                  <Col sm={12}>
                    <Form.Item
                      label="Client"
                      name="client"
                      rules={[
                        { required: true, message: "Please select a client!" },
                      ]}
                    >
                      <Select options={clients} className="" />
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col sm={12}>
                <Form.Item
                  label="Status"
                  name="status"
                  rules={[
                    { required: true, message: "Please select a status!" },
                  ]}
                >
                  <Select>
                    <Option value="ACTIVE">ACTIVE</Option>
                    <Option value="INACTIVE">IN-ACTIVE</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col sm={12}>
                <Form.Item label="Profile Image" name="profilePicUrl">
                  {profilePicWithUrlChecked === true ? (
                    inputComponent
                  ) : (
                    <input
                      type="file"
                      onChange={handleFileChange}
                      className="upload-list-block"
                    />
                  )}
                </Form.Item>
                <Form.Item label="" name="profilePicUrl">
                  <Checkbox
                    checked={profilePicWithUrlChecked}
                    onChange={handleCheckboxChange}
                    className="form-label-antd profilePicWithUrlCheckBox"
                    name="profilePicWithUrlCheckBox"
                  >
                    Upload with url
                  </Checkbox>
                </Form.Item>
              </Col>
              <div className="d-flex justify-content-center w-100">
                <Button
                  type="primary"
                  htmlType="reset"
                  className="submit-btn border-0 me-4 rounded"
                >
                  Clear
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-btn border-0 me-0 rounded"
                >
                  Submit
                </Button>
              </div>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};
export default AddUser;
