import React from "react";
import "../../../App.css";
const SurveyExpired = () => {
  return (
    <div className="underMaintainance-container">
      <h1 className="text1">The survey has expired.</h1>
    </div>
  );
};

export default SurveyExpired;
