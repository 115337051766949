import { useState, useEffect, useRef } from "react";
import { InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import secureLocalStorage from "react-secure-storage";
import { msalConfig } from "./msalConfig";
import { storeAccessToken, storeRefreshToken } from "./tokenStorage";
import { useNavigate } from "react-router-dom";

const msalInstance = new PublicClientApplication(msalConfig);

const TOKEN_REFRESH_THRESHOLD = 300000; // 5 minutes in milliseconds

const TokenRefresher = () => {
  const navigate = useNavigate();
  const [prevAccessTokenExpiry, setPrevAccessTokenExpiry] = useState(null);
  const timeoutRef = useRef(null);

  const scheduleTokenRefresh = async () => {
    try {
      const currentTimestamp = Date.now();
      const customAccessTokenExpiry = currentTimestamp + 60 * 1000; // 1 minute in milliseconds

      let accessTokenExpiry =
        customAccessTokenExpiry ||
        secureLocalStorage.getItem("accessTokenExpiry");
      //console.log("Access token expiry:", accessTokenExpiry);
      if (
        !accessTokenExpiry ||
        currentTimestamp > accessTokenExpiry - TOKEN_REFRESH_THRESHOLD
      ) {
        //console.log("Access token expired or near expiry, refreshing...");
        const account = msalInstance.getActiveAccount();
        if (!account) {
          //console.log("No active account, prompting for sign-in...");
          msalInstance.loginPopup({
            scopes: ["user.read", "offline_access"],
          });
          return;
        }

        const tokenResponse = await msalInstance.acquireTokenSilent({
          account: account,
          scopes: ["user.read", "offline_access"],
        });

        storeAccessToken(tokenResponse.accessToken, tokenResponse.expiresOn);
        storeRefreshToken(
          tokenResponse.refreshToken,
          tokenResponse.extExpiresOn.getTime()
        );
        //console.log("New access token and refresh token stored.");
      }

      const expiresIn = accessTokenExpiry - currentTimestamp;
      const refreshTimeout =
        expiresIn > 0 ? expiresIn - TOKEN_REFRESH_THRESHOLD : 0;
      timeoutRef.current = setTimeout(scheduleTokenRefresh, 300000);
      //console.log("Token refresh scheduled successfully.");

      // Update previous access token expiry
      setPrevAccessTokenExpiry(accessTokenExpiry);
    } catch (error) {
      console.error("Error refreshing token:", error);
      if (error instanceof InteractionRequiredAuthError) {
        // If silent sign-in fails due to no user signed in, initiate interactive sign-in
        try {
          const interactiveRequest = {
            scopes: ["user.read", "offline_access"],
          };
          const interactiveResponse = await msalInstance.acquireTokenPopup(interactiveRequest);
          storeAccessToken(interactiveResponse.accessToken, interactiveResponse.expiresOn);  
          navigate("/auditForms", { replace: true });    
        } catch (error) {
          console.error("Error acquiring token:", error);
          // Handle error
        }
      } else {
        console.error("Error acquiring token:", error);
        // Handle error
      }
    }
  };

  useEffect(() => {
    //console.log("TokenRefresher component mounted.");
    scheduleTokenRefresh();
    return () => {
      // clearTimeout(timeoutRef.current);
      //console.log("TokenRefresher component unmounted.");
    };
  }, []);

  return null;
};

export default TokenRefresher;
