import React, { useState, useEffect, useContext } from "react";
import { Table } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import APICaller from "../../../utils/APICaller";
import "./feedbackRequests.css";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import { GlobalStateContext } from "../../../utils/GlobalStateContext";
import BeforeTableHeader from "../../layouts/beforeTableHeader";
import HandleSearch from "../../../utils/CommonSearchComponent";
import { JsonToExcel } from "react-json-to-excel";
import { exportJson } from "../../../utils/export";

const FeedbackRequests = ({ children }) => {
  const { type } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [showLoader, setShowLoader] = useState(true);
  const [selectedRowsArray, setSelectedRowsArray] = useState([]);

  let columns = [
    {
      title: () => {
        return <div className="tableTitle">ID</div>;
      },
      dataIndex: "id",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Type Of Form</div>;
      },
      dataIndex: "formType",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Recipient</div>;
      },
      dataIndex: "recipient",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Status</div>;
      },
      dataIndex: "status",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Created On</div>;
      },
      dataIndex: "createdAt",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Feedback Link</div>;
      },
      dataIndex: "feedBackLink",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
  ];

  if (location.pathname.includes("/closed")) {
    columns.splice(5, 0, {
      title: () => {
        return <div className="tableTitle">Captured On</div>;
      },
      dataIndex: "capturedOn",
      render: (text) => <span className="rowstyle">{text}</span>,
    });
    columns.push({
      title: () => {
        return <div className="tableTitle">Preview</div>;
      },
      dataIndex: "previewBy",
      render: (text) => (
        <span className="rowstyle previewBy">
          {text ? <span onClick={() => handlePreview(text)}>View</span> : ""}
        </span>
      ),
    });
  }

  const handlePreview = async (id) => {
    const previewDetails = {};
    previewDetails.previewedBy = secureLocalStorage.getItem("lIUId");
    previewDetails.previewedAt = new Date(
      new Date().getTime() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const response = await APICaller(
      secureLocalStorage.getItem("role"),
      secureLocalStorage.getItem("accessToken"),
      "/feedback-requests/survey/preview",
      "POST",
      {
        id: id,
        previewDetails: previewDetails && JSON.stringify(previewDetails),
      }
    );
    navigate("/feedback-requests/preview/" + id, { replace: true });
  };
  const [selectionType, setSelectionType] = useState("checkbox");

  /*begin::getFeedbackRequestsList will fetch list of users*/
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });
  const getFeedbackRequestsList = async (source = "", pagination = "") => {
    setShowLoader(true);
    try {
      setGlobalState({ ...globalState, list: [] });
      let hasSearchedValue, searchKeyword;
      if (secureLocalStorage.getItem("hasSearchedValue")) {
        hasSearchedValue = "yes";
        searchKeyword = secureLocalStorage.getItem("searchKeyword");
      } else {
        hasSearchedValue = "";
        searchKeyword = "";
      }
      const searchIn = location.pathname.split("/")[1];
      const type = location.pathname.split("/")[2];
      const callActionFrom = source ? source : "";
      const globalStates = {
        globalState: globalState,
        setGlobalState: setGlobalState,
      };

      setSelectedRowKeys([]);
      const allStates = {
        showLoader: showLoader,
        setShowLoader: setShowLoader,
        selectedRowsArray: selectedRowsArray,
        setSelectedRowsArray: setSelectedRowsArray,
        pagination: pagination
          ? pagination
          : { current: 1, page: 1, pageSize: 100 },
        setPagination: setPagination,
        list: list,
        setList: setList,
      };

      await HandleSearch(
        hasSearchedValue,
        searchIn,
        type,
        callActionFrom,
        globalStates,
        allStates
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setShowLoader(false); // Hide loader after fetching and updating data
    }
  };

  useEffect(() => {
    setList([]);
    getFeedbackRequestsList();
  }, [type]);

  /*end::getFeedbackRequestsList will fetch list of users*/

  {
    /*Export functionality on VOC Form Listing's table clicking on checkboxes*/
  }
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      const exportColumns = [
        { key: "Type of form", path: "form.formType" },
        { key: "Recipient", path: "recipient" },
        { key: "Status", path: "status" },
        {
          key: "Created At",
          path: "createdAt",
          formatter: (value) =>
            value ? moment(value).format("YYYY-MM-DD HH:mm A") : "",
        },
        {
          key: "Captured On",
          path: "capturedOn",
          formatter: (value) =>
            value ? moment(value).format("YYYY-MM-DD HH:mm A") : "",
        },
        {
          key: "Reminder Send On",
          path: "reminderSentOn",
          formatter: (value) =>
            value ? moment(value).format("YYYY-MM-DD HH:mm A") : "",
        },
        {
          key: "FeedBack Link",
          formatter: (feedbackRequestLists) =>
            `${process.env.REACT_APP_URI}/survey/${feedbackRequestLists._id}`,
        },
        {
          key: "Score",
          path: "score",
        },
      ];

      exportJson(
        selectedRows,
        globalState?.feedbackRequestLists,
        "FeedbackRequests",
        exportColumns,
        setGlobalState,
        globalState
      );
    },
  };

  return (
    <>
      <div className="all-content">
        <div className="btn-wraps">
          <BeforeTableHeader
            list={list && list.length}
            showListingCount="yes"
            href=""
          />
          {/*Export and Delete buttons*/}

          <div className="right-buttons">
            {(secureLocalStorage.getItem("role") === "admin" ||
              secureLocalStorage.getItem("role") === "qc") && (
              <>
                {globalState.selectedRowKeysChildInfo &&
                  globalState.selectedRowKeysChildInfo.length > 0 && (
                    <JsonToExcel
                      title="Export as Excel"
                      data={globalState.exportFeedbackRequestsJson}
                      fileName="feedbackRequest"
                      btnClassName="table-buttons"
                    />
                  )}
              </>
            )}
            <div className="feedbackInfoContainer">
              <span className="frSent">Sent: {globalState?.sent}</span>
              <span className="frReverted">
                Reverted: {globalState?.reverted}
              </span>
              <span className="frNotReverted">
                Not Reverted: {globalState?.notReverted}
              </span>
            </div>
          </div>
        </div>
        <div className="all-table-holder">
          {showLoader === true && (
            <div className="customLoader">Loading...</div>
          )}
          <Table
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            columns={columns}
            dataSource={globalState.list}
            pagination={globalState?.pagination}
            onChange={(e) => {
              getFeedbackRequestsList("pagination", e);
            }}
          />
        </div>
      </div>
    </>
  );
};
export default FeedbackRequests;
