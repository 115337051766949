import React from "react";
import "../../App.css";
const UnderMaintenance = () => {
  return (
    <div className="underMaintainance-container">
      <h1 className="text1">We&rsquo;ll be back soon!</h1>
      <h3 className="uc">
        Sorry for the inconvenience but we&rsquo;re performing some maintenance
        at the moment !
      </h3>
    </div>
  );
};

export default UnderMaintenance;
