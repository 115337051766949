import React, { useState, useEffect, useContext } from "react";
import { Table } from "antd";
import { useLocation } from "react-router-dom";
import "./users.css";
import secureLocalStorage from "react-secure-storage";
import { GlobalStateContext } from "../../../utils/GlobalStateContext";
import BeforeTableHeader from "../../layouts/beforeTableHeader";
import HandleSearch from "../../../utils/CommonSearchComponent";

const Users = ({ children }) => {
  const location = useLocation();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [showLoader, setShowLoader] = useState(true);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {},
  };

  const columns = [
    {
      title: () => {
        return <div className="tableTitle"> ID</div>;
      },
      dataIndex: "id",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle"> Emp ID</div>;
      },
      dataIndex: "empID",
      render: (text, row) => <span className="rowstyle">{text}</span>,
    },
    ,
    {
      title: () => {
        return <div className="tableTitle"> Name</div>;
      },
      dataIndex: "name",
      render: (text, row) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle"> Email</div>;
      },
      dataIndex: "email",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle"> Role</div>;
      },
      dataIndex: "role",
      render: (text) => (
        <span className="rowstyle">
          {text && text.charAt(0).toUpperCase() + text.slice(1)}
        </span>
      ),
    },
    {
      title: () => {
        return <div className="tableTitle">Client</div>;
      },
      dataIndex: "client",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle"> Created At</div>;
      },
      dataIndex: "created_at",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
  ];
  const [selectionType, setSelectionType] = useState("checkbox");

  /*begin::getUserList will fetch list of users*/
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });
  const getUserList = async (source = "", pagination = "") => {
    let hasSearchedValue, searchKeyword;
    if (secureLocalStorage.getItem("hasSearchedValue")) {
      hasSearchedValue = "yes";
      searchKeyword = secureLocalStorage.getItem("searchKeyword");
    } else {
      hasSearchedValue = "";
      searchKeyword = "";
    }
    const searchIn = location.pathname.replace("/", "");
    const callActionFrom = source ? source : "";
    const globalStates = {
      globalState: globalState,
      setGlobalState: setGlobalState,
    };

    const allStates = {
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 50 },
      setPagination: setPagination,
      list: list,
      setList: setList,
    };

    const allRecs = await HandleSearch(
      hasSearchedValue,
      searchIn,
      searchKeyword,
      callActionFrom,
      globalStates,
      allStates
    );
  };

  useEffect(() => {
    getUserList();
  }, []);

  /*end::getUserList will fetch list of users*/

  return (
    <>
      <div className="all-content">
        <div className="d-flex">
          <BeforeTableHeader
            list={list && list.length}
            showListingCount="yes"
            href="/users/add"
          />
        </div>

        <div className="all-table-holder">
          {showLoader === true && (
            <div className="customLoader">Loading...</div>
          )}
          <Table
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            columns={columns}
            dataSource={globalState.list}
            pagination={globalState?.pagination}
            onChange={(e) => {
              getUserList("pagination", e);
            }}
          />
        </div>
      </div>
    </>
  );
};
export default Users;
