import React, { useContext, useEffect, useState } from "react";
import BeforeTableHeader from "../../layouts/beforeTableHeader";
import { Table } from "antd";
import { GlobalStateContext } from "../../../utils/GlobalStateContext";

import { Link, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import HandleSearch from "../../../utils/CommonSearchComponent";
import moment from "moment";
import { exportJson } from "../../../utils/export";
import { JsonToExcel } from "react-json-to-excel";

const DeletedAudits = ({ children }) => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);

  const location = useLocation();

  {
    /*Declaring states*/
  }
  const [showLoader, setShowLoader] = useState(false);
  const [list, setList] = useState([]),
    [searchData, setSearchData] = useState([]),
    [selectedRowsArray, setSelectedRowsArray] = useState([]),
    [selectedRowKeys, setSelectedRowKeys] = useState([]),
    [listItems, setListItems] = useState([]),
    [allTransactionIdsArr, setAllTransactionIdsArr] = useState([]);

  const [selectionType, setSelectionType] = useState("checkbox");
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });

  const columns = [
    {
      title: () => {
        return <div className="tableTitle"> ID</div>;
      },
      dataIndex: "id",
      render: (text, row) => (
        <Link
          to={"/deleted-audits/" + row.id}
          state={{
            route: location.pathname,
            formMethd: row.formMethd,
          }}
          style={{ color: "black" }}
        >
          <span
            className="rowstyle"
            style={{ color: "#1875CB" }}
            onClick={handleDeleteAuditDetailClickedLink}
          >
            {text}
          </span>
        </Link>
      ),
    },
    {
      title: () => {
        return <div className="tableTitle">Form</div>;
      },
      dataIndex: "form",
      render: (text, row) =>
        secureLocalStorage.getItem("role") === "tl" ||
        secureLocalStorage.getItem("role") === "atl" ? (
          <span className="rowstyle">{text}</span>
        ) : (
          <Link
            to={"/auditForms/" + row.formId}
            state={{ route: "/auditForms" }}
            style={{ color: "black" }}
          >
            <span
              className="rowstyle"
              style={{ color: "#1875CB" }}
              onClick={() =>
                secureLocalStorage.setItem("fromComponent", "delete-audits")
              }
            >
              {text}
            </span>
          </Link>
        ),
    },
    {
      title: () => {
        return <div className="tableTitle">Reason</div>;
      },
      dataIndex: "auditDeleteReason",
      render: (text, row) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Transaction ID</div>;
      },
      dataIndex: "transaction_id",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Auditor</div>;
      },
      dataIndex: "auditor",
      render: (text) => <span className="rowstyle">{text?.name}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Agent</div>;
      },
      dataIndex: "agent",
      render: (text) => <span className="rowstyle">{text?.name}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Supervisor</div>;
      },
      dataIndex: "supervisor",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Audit Date</div>;
      },
      dataIndex: "createdAt",
      render: (text) => (
        <span className="rowstyle">
          {text && moment(text).format("DD-MM-YY HH:mm A")}
        </span>
      ),
    },
    {
      title: () => {
        return <div className="tableTitle">Transaction Date</div>;
      },
      dataIndex: "transaction_date",
      render: (text) => (
        <span className="rowstyle">
          {text && moment(text).format("DD-MM-YY HH:mm A")}
        </span>
      ),
    },
    {
      title: () => {
        return <div className="tableTitle">Status</div>;
      },
      dataIndex: "stage",
      render: (text) => (
        <span className="rowstyle">
          {" "}
          <img
            src={
              text === "Audit Completed"
                ? "audited.PNG"
                : text === "Audit Pending"
                ? "pending.png"
                : text === "Accepted"
                ? "completed.png"
                : "disputed.png"
            }
            style={{ width: "17px" }}
            alt="logo"
          />
          &nbsp;{text?.toUpperCase()}
        </span>
      ),
    },
    {
      title: () => {
        return <div className="tableTitle"> Score</div>;
      },
      dataIndex: "score",
      render: (text, row) => <span className="rowstyle">{text}%</span>,
    },
    {
      title: () => {
        return <div className="tableTitle"> Active</div>;
      },
      dataIndex: "active",
      render: (text) => (
        <span
          className={
            text?.toUpperCase() === "ACTIVE"
              ? "rowstyle adtAS"
              : "rowstyle adtCS"
          }
        >
          {text && text?.toUpperCase()}
        </span>
      ),
    },
    {
      title: () => {
        return <div className="tableTitle"> </div>;
      },
      dataIndex: "comments",
      render: (text) => (
        <span className="rowstyle">
          {" "}
          <img
            src={text > 0 ? "comment.png" : "noComment.png"}
            style={{ width: "17px" }}
            alt="logo"
          />
          &nbsp;{text}
        </span>
      ),
    },
    {
      title: () => {
        return <div className="tableTitle"> </div>;
      },
      dataIndex: "auditor",
      render: (text) => (
        <span className="rowstyle">
          {text && text?.profilePicUrl ? (
            <img
              className="auditProfileImage"
              src={
                process.env.REACT_APP_BACKEND +
                "/images/" +
                text?.profilePicUrl +
                ".jpg"
              }
              alt={text?.name}
            />
          ) : (
            <img
              className="auditProfileImage"
              src={process.env.REACT_APP_BACKEND + "/images/dummy.jpg"}
              alt={text?.name}
            />
          )}
        </span>
      ),
    },
  ];

  {
    /*begin::Setting some localstorage values on click of Deleted Audit Id's link*/
  }
  const handleDeleteAuditDetailClickedLink = () => {
    secureLocalStorage.setItem("clickedLink", "deleteAuditsDetailLinkClick");
    secureLocalStorage.setItem("fromComponent", "deleted-audits");
  };
  {
    /*end::Setting some localstorage values on click of Deleted Audit Id's link*/
  }
  {
    /*begin::Fetching all list of Deleted Audits*/
  }

  {
    /*begin::Fetching all list of Deleted Audits*/
  }
  const getDeletedAuditsList = async (source = "", pagination = "") => {
    const allKeys = Object.keys(localStorage);
    let removeItems;
    switch (secureLocalStorage.getItem("fromComponent")) {
      case "deleted-audits": {
        removeItems = [
          "@secure.n.totalAuditedCount",
          "@secure.s.auditScore",
          "@secure.j.parameterSelectedList",
          "@secure.j.auditFormInput",
          "@secure.s.auditFormCall",
          "@secure.n.auditedQuestionScoreCount",
          "@secure.n.auditedQuestionCount",
          "@secure.j.countMap",
          "@secure.s.clickedLink",
          "@secure.j.timeStampsDetails",
          "@secure.n.weightageFlag",
          "@secure.j.updatedQMF",
          "@secure.s.savedAuditId",
          "@secure.b.auditedFormSubmit",
          "@secure.s.aID",
          "@secure.j.savePayloadRequestParams",
        ];
        break;
      }
      default: {
        break;
      }
    }
    allKeys &&
      allKeys.forEach((key) => {
        if (removeItems && removeItems.includes(key)) {
          localStorage.removeItem(key);
        }
      });
    let hasSearchedValue, searchKeyword;
    if (secureLocalStorage.getItem("hasSearchedValue")) {
      hasSearchedValue = "yes";
      searchKeyword = secureLocalStorage.getItem("searchKeyword");
    } else {
      hasSearchedValue = "";
      searchKeyword = "";
    }
    const searchIn = location.pathname.replace("/", "");
    const callActionFrom = source ? source : "";
    const globalStates = {
      globalState: globalState,
      setGlobalState: setGlobalState,
    };
    const { current, pageSize } = pagination;

    setSelectedRowKeys([]);
    const allStates = {
      searchData: searchData,
      setSearchData: setSearchData,
      selectedRowsArray: selectedRowsArray,
      setSelectedRowsArray: setSelectedRowsArray,
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 100 },
      setPagination: setPagination,
      list: list,
      setList: setList,

      listItems,
      setListItems,
      allTransactionIdsArr: allTransactionIdsArr,
      setAllTransactionIdsArr: setAllTransactionIdsArr,
    };

    const allRecs = await HandleSearch(
      hasSearchedValue,
      searchIn,
      searchKeyword,
      callActionFrom,
      globalStates,
      allStates
    );
  };
  useEffect(() => {
    getDeletedAuditsList();
  }, []);
  {
    /*end::Fetching all list of Deleted Audits*/
  }

  {
    /*Export functionality on Audit Listing's table clicking on checkboxes*/
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      const exportColumns = [
        { key: "Auditor", path: "auditor.name" },
        { key: "Audit Score", path: "score" },
        { key: "Client", path: "client.name" },
        { key: "Department", path: "department.name" },
        { key: "Process", path: "process.name" },
        { key: "Audit Form", path: "auditForm.formName" },
        { key: "Transaction ID", path: "transaction_id" },
        {
          key: "Transaction Date",
          path: "transactionDate",
          formatter: (value) =>
            value ? moment(value).format("YYYY-MM-DD HH:mm A") : "",
        },
        {
          key: "Reason for Audit's delete",
          path: "auditDeleteReason",
        },

        { key: "Reason for Audit's back date", path: "auditBackDateReason" },
        {
          key: "Reason for duplicate Transaction ID",
          path: "transactionDuplicateReason",
        },

        { key: "Transaction Amount", path: "transactionAmount" },
        { key: "Invoice count", path: "invoiceCount" },
        { key: "Insured Name", path: "insuredName" },
        { key: "Supervisor", path: "agentReportingSupervisor.name" },
        { key: "Status", path: "stage" },
        { key: "Agent Name", path: "agent.name" },
        { key: "Agent Emp ID", path: "agent.empID" },
        {
          key: "Fatal",
          path: "fatalNotFatalInfo",
          formatter: (value) => (value ? JSON.parse(value).fatal : ""),
        },
        {
          key: "Not Fatal",
          path: "fatalNotFatalInfo",
          formatter: (value) => (value ? JSON.parse(value).notFatal : ""),
        },
        {
          key: "Created At",
          path: "createdAt",
          formatter: (value) =>
            value ? moment(value).format("YYYY-MM-DD HH:mm A") : "",
        },
        {
          key: "Updated At",
          path: "updatedAt",
          formatter: (value) =>
            value ? moment(value).format("YYYY-MM-DD HH:mm A") : "",
        },
      ];

      exportJson(
        selectedRows,
        globalState?.deletedAuditLists,
        "DeletedAuditReport",
        exportColumns,
        setGlobalState,
        globalState
      );
    },
  };
  return (
    <div className="all-content">
      <div className="d-flex">
        <BeforeTableHeader
          list={list && list.length}
          showListingCount="yes"
          href=""
        />
        <div className="right-buttons">
          {(secureLocalStorage.getItem("role") === "admin" ||
            secureLocalStorage.getItem("role") === "qc") && (
            <>
              {globalState.selectedRowKeysChildInfo &&
                globalState.selectedRowKeysChildInfo.length > 0 && (
                  <JsonToExcel
                    title="Export as Excel"
                    data={globalState.exportDeletedAuditReportJson}
                    fileName="deletedAuditReport"
                    btnClassName="table-buttons"
                  />
                )}
            </>
          )}
        </div>
      </div>
      <div className="all-table-holder">
        {showLoader === true && <div className="customLoader">Loading...</div>}
        <Table
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          columns={columns}
          dataSource={globalState.list}
          pagination={globalState?.pagination}
          onChange={(e) => {
            getDeletedAuditsList("pagination", e);
          }}
        />
      </div>
    </div>
  );
};

export default DeletedAudits;
