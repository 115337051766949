import { Button, Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Table } from "antd";
import "./escalations.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BeforeTableHeader from "../../layouts/beforeTableHeader";
import { GlobalStateContext } from "../../../utils/GlobalStateContext";
import moment from "moment";
import APICaller from "../../../utils/APICaller";
import secureLocalStorage from "react-secure-storage";
import DuplicateTransactionReasonForm from "../audits/duplicateTransactionReasonForm";
import { JsonToExcel } from "react-json-to-excel";
import HandleSearch from "../../../utils/CommonSearchComponent";
import { exportJson } from "../../../utils/export";

const Escalations = ({ children = null }) => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [showLoader, setShowLoader] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [list, setList] = useState([]),
    [listItems, setListItems] = useState([]),
    [transactionDuplicateReason, setTransactionDuplicateReason] = useState(""),
    [allTransactionIdsArr, setAllTransactionIdsArr] = useState([]),
    [visible, setVisible] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const getEscalationsList = async (source = "", pagination = "") => {
    let hasSearchedValue, searchKeyword;
    if (secureLocalStorage.getItem("hasSearchedValue")) {
      hasSearchedValue = "yes";
      searchKeyword = secureLocalStorage.getItem("searchKeyword");
    } else {
      hasSearchedValue = "";
      searchKeyword = "";
    }
    const searchIn = location.pathname.replace("/", "");
    const callActionFrom = source ? source : "";
    const globalStates = {
      globalState: globalState,
      setGlobalState: setGlobalState,
    };

    const allStates = {
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 50 },
      setPagination: setPagination,
      list: list,
      setList: setList,

      allTransactionIdsArr: allTransactionIdsArr,
      setAllTransactionIdsArr: setAllTransactionIdsArr,
      listItems: listItems,
      setListItems: setListItems,
    };

    const allRecs = await HandleSearch(
      hasSearchedValue,
      searchIn,
      searchKeyword,
      callActionFrom,
      globalStates,
      allStates
    );
  };

  {
    /*Setting state for showing Duplication Transaction ID popup as well as it value on Audits Detail Section*/
  }
  const onCreate = (values) => {
    values?.transactionDuplicateReason &&
      setTransactionDuplicateReason(values?.transactionDuplicateReason);
    //"Reason for duplicate Transaction ID"
    setGlobalState((prevGlobalState) => ({
      ...prevGlobalState,
      transactionDuplicateReason:
        values?.transactionDuplicateReason &&
        values?.transactionDuplicateReason,
    }));
    setVisible(false);
  };
  {
    /*Create Escalation - Submit click*/
  }
  const handleOk = () => {
    let submitFormModal = "";
    if (isModalVisible === true) {
      submitFormModal = "clickedOK";
    }

    const lIUId = secureLocalStorage.getItem("lIUId");

    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (
      allTransactionIdsArr.includes(globalState?.eTransactionIdVal) &&
      transactionDuplicateReason === ""
    ) {
      setIsModalOpen(true);
      setVisible(true);
    } else if (
      globalState?.eClientSpocEmailVal &&
      emailRegex.test(globalState?.eClientSpocEmailVal) === false
    ) {
      setIsModalOpen(true);
      setGlobalState((prevGlobalState) => ({
        ...prevGlobalState,
        eClientSpocEmail: "Please enter a valid email adddress.",
      }));
    } else if (
      globalState?.eClientName &&
      globalState?.eClientSpocEmailVal &&
      globalState?.eAgentName &&
      globalState?.eDepartmentName &&
      globalState?.eProcessName &&
      globalState?.eReportingTlName &&
      globalState?.eErrorRaiseDateVal &&
      globalState?.eTransactionIdVal &&
      globalState?.eTransactionDateVal &&
      globalState?.eErrorDescriptionVal &&
      globalState?.eErrorInvestigationRemarksVal &&
      globalState?.eErrorImpactVal
    ) {
      let transactionDate = moment(globalState?.eTransactionDateVal).format(
        "YYYY-MM-DD"
      );

      let eErrorRaiseDate = moment(globalState?.eErrorRaiseDateVal).format(
        "YYYY-MM-DD"
      );
      let createdAt = new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");
      let createdBy = lIUId;
      let stage = "Completed";
      let status = "ACTIVE";
      if (submitFormModal === "clickedOK") {
        setIsModalOpen(false);
        setIsModalVisible(false);
        setVisible(false);
        APICaller(
          secureLocalStorage.getItem("role"),
          secureLocalStorage.getItem("accessToken"),
          "/escalations",
          "POST",
          {
            client: globalState?.eClientId,
            clientSpocEmail: globalState?.eClientSpocEmailVal,
            agent: globalState?.eAgentId,
            department: globalState?.eDepartmentId,
            process: globalState?.eProcessId,
            reportingTl: globalState?.eReportingTlId,
            transactionId: globalState?.eTransactionId,
            transactionDuplicateReason: transactionDuplicateReason,
            transactionDate: transactionDate,
            errorRaiseDate: eErrorRaiseDate,
            errorDescription: globalState?.eErrorDescription,
            errorInvestigationRemarks: globalState?.eErrorInvestigationRemarks,
            errorImpact: globalState?.eErrorImpact,
            stage: stage,
            status: status,
            createdAt: createdAt,
            createdBy: createdBy,
          }
        )
          .then((response) => {
            navigate("/escalations/" + response?._id, {
              state: {
                route: location.pathname,
                client: response?.client?.name,
                clientSpocEmail: response?.clientSpocEmail,
                agent: response?.agent?.name,
                department: response?.department?.name,
                process: response?.process?.name,
                reportingTl: response?.reportingTl?.name,
                transactionDate: response?.transactionDate,
                transactionId: response?.eTransactionId,
                errorRaiseDate: response?.eErrorRaiseDate,
                errorDescription: response?.eErrorDescription,
                errorInvestigationRemarks: response?.eErrorInvestigationRemarks,
                errorImpact: response?.eErrorImpact,
              },
            });
          })
          .catch((error) => {});
      } else {
        setIsModalOpen(true);
        setIsModalVisible(true);
      }
    } else {
      setIsModalOpen(true);

      setGlobalState((prevGlobalState) => ({
        ...prevGlobalState,
        eClientId: globalState?.eClientName
          ? globalState?.eClientId
            ? globalState?.eClientId
            : ""
          : "Client is required",
        eClientSpocEmail: globalState?.eClientSpocEmailVal
          ? globalState?.eClientSpocEmailVal
          : "Client SPOC Email is required",
        eAgentId: globalState?.eAgentName
          ? globalState?.eAgentId
            ? globalState?.eAgentId
            : ""
          : "Agent is required",
        eDepartmentId: globalState?.eDepartmentName
          ? globalState?.eDepartmentId
            ? globalState?.eDepartmentId
            : ""
          : "Department is required",
        eProcessId: globalState?.eProcessName
          ? globalState?.eProcessId
            ? globalState?.eProcessId
            : ""
          : "Process is required",
        eReportingTlId: globalState?.eReportingTlName
          ? globalState?.eReportingTlId
            ? globalState?.eReportingTlId
            : ""
          : "Reporting TL is required",
        eErrorRaiseDate: globalState?.eErrorRaiseDateVal
          ? globalState?.eErrorRaiseDateVal
          : "Error raise date is required",
        eTransactionId: globalState?.eTransactionIdVal
          ? globalState?.eTransactionIdVal
          : "Transaction ID is required",
        eTransactionDate: globalState?.eTransactionDateVal
          ? globalState?.eTransactionDateVal
          : "Transaction Date is required",
        eErrorDescription: globalState?.eErrorDescriptionVal
          ? globalState?.eErrorDescriptionVal
          : "Error description is required",
        eErrorInvestigationRemarks: globalState?.eErrorInvestigationRemarksVal
          ? globalState?.eErrorInvestigationRemarksVal
          : "Error investigation remarks is required",
        eErrorImpact: globalState?.eErrorImpactVal
          ? globalState?.eErrorImpactVal
          : "Error impact is required",
      }));
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [selectionType, setSelectionType] = useState("checkbox");

  const columns = [
    {
      title: () => {
        return <div className="tableTitle"> ID</div>;
      },
      dataIndex: "id",
      render: (text) => (
        <span className="rowstyle">
          <Link to={"/escalations/" + text} style={{ color: "black" }}>
            <span
              className="rowstyle"
              style={{ color: "#1875CB" }}
              onClick={() => {
                secureLocalStorage.setItem("fromComponent", "escalations");
              }}
            >
              {text}
            </span>
          </Link>
        </span>
      ),
    },
    {
      title: () => {
        return <div className="tableTitle">Impact</div>;
      },
      dataIndex: "errorImpact",
      align: "center",
      render: (text) => (
        <span className="rowstyle">
          {" "}
          <img
            src={
              text === "HIGH"
                ? "high.png"
                : text === "MEDIUM"
                ? "medium.png"
                : "low.png"
            }
            style={{ width: "17px" }}
            alt="logo"
          />
          &nbsp;{}
        </span>
      ),
    },

    {
      title: () => {
        return <div className="tableTitle">Transaction ID</div>;
      },
      dataIndex: "transaction",
      render: (text, row) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Department</div>;
      },
      dataIndex: "department",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Client</div>;
      },
      dataIndex: "client",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Status</div>;
      },
      dataIndex: "stage",
      render: (text) => (
        <span className="rowstyle">
          {" "}
          <img
            src={
              text === "Audited"
                ? "audited.PNG"
                : text === "Pending"
                ? "pending.png"
                : text === "Completed"
                ? "completed.png"
                : "disputed.png"
            }
            style={{ width: "17px" }}
            alt="logo"
          />
          &nbsp;{text}
        </span>
      ),
    },
    {
      title: () => {
        return <div className="tableTitle">Active</div>;
      },
      dataIndex: "status",
      render: (text) => (
        <span
          className="rowstyle"
          style={{ color: text == "ACTIVE" ? "#219653" : "#BDBDBD" }}
        >
          {text}
        </span>
      ),
    },
    {
      title: () => {
        return <div className="tableTitle">Raised Date</div>;
      },
      dataIndex: "errorRaiseDate",
      render: (text) => <span className="rowstyle">{text}</span>,
    },

    {
      title: () => {
        return <div className="tableTitle">Reporting TL</div>;
      },
      align: "center",
      dataIndex: "reportingTl",
      render: (text) => (
        <span className="rowstyle">
          <img
            className="auditProfileImage"
            src={
              text?.profilePicUrl
                ? process.env.REACT_APP_BACKEND +
                  "/images/" +
                  text?.profilePicUrl +
                  ".jpg"
                : process.env.REACT_APP_BACKEND + "/images/dummy.jpg"
            }
            alt={text?.name}
          />
        </span>
      ),
    },
    {
      title: () => {
        return <div className="tableTitle">Agent</div>;
      },
      dataIndex: "agent",
      render: (text) => (
        <span className="rowstyle">
          <img
            className="auditProfileImage"
            src={
              text?.profilePicUrl
                ? process.env.REACT_APP_BACKEND +
                  "/images/" +
                  text?.profilePicUrl +
                  ".jpg"
                : process.env.REACT_APP_BACKEND + "/images/dummy.jpg"
            }
            alt={text?.name}
          />
        </span>
      ),
    },
    {
      dataIndex: "escalation_id",
      render: (text) => <></>,
    },
  ];

  {
    /*Export functionality on Escalation Listing's table clicking on checkboxes*/
  }
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);

      const exportColumns = [
        { key: "Client", path: "client.name" },
        { key: "Client SPOC Email", path: "clientSpocEmail" },
        { key: "Agent", path: "agent.name" },
        { key: "Department", path: "department.name" },
        { key: "Process", path: "process.name" },
        { key: "Reporting TL", path: "reportingTl.name" },
        {
          key: "Error Raise Date",
          path: "errorRaiseDate",
          formatter: (value) =>
            value ? moment(value).format("YYYY-MM-DD HH:mm A") : "",
        },
        { key: "Transaction ID", path: "transactionId" },
        {
          key: "Transaction Duplicate Reason",
          path: "transactionDuplicateReason",
        },
        {
          key: "Transaction Date",
          path: "transactionDate",
          formatter: (value) =>
            value ? moment(value).format("YYYY-MM-DD HH:mm A") : "",
        },
        { key: "Error Description", path: "errorDescription" },
        {
          key: "Error Investigation Remarks",
          path: "errorInvestigationRemarks",
        },
        { key: "Error Impact", path: "errorImpact" },
        { key: "Stage", path: "stage" },
        { key: "Status", path: "status" },
        {
          key: "Created At",
          path: "createdAt",
          formatter: (value) =>
            value ? moment(value).format("YYYY-MM-DD HH:mm A") : "",
        },
        {
          key: "Updated At",
          path: "updatedAt",
          formatter: (value) =>
            value ? moment(value).format("YYYY-MM-DD HH:mm A") : "",
        },
      ];
      exportJson(
        selectedRows,
        globalState?.escalationLists,
        "EscalationReport",
        exportColumns,
        setGlobalState,
        globalState
      );

      /*if (escalationListing.hasOwnProperty("response")) {
        const allEscalationsArray = escalationListing.response;
        const modifiedEscalations = allEscalationsArray.map(
          (escalationLists) => {
            if (selectedRowKeysChildInfoArr.includes(escalationLists?._id)) {
              const clientName = escalationLists?.client?.name;
              const clientSpocEmail = escalationLists?.clientSpocEmail;
              const agentName = escalationLists?.agent?.name;
              const departmentName = escalationLists?.department?.name;
              const processName = escalationLists?.process?.name;
              const reportingTlName = escalationLists?.reportingTl?.name;
              const errorRaiseDate = escalationLists?.errorRaiseDate;
              const transactionId = escalationLists?.transactionId;
              const transactionDuplicateReason =
                escalationLists?.transactionDuplicateReason;
              const transactionDate = escalationLists?.transactionDate;
              const errorDescription = escalationLists?.errorDescription;
              const errorInvestigationRemarks =
                escalationLists?.errorInvestigationRemarks;
              const errorImpact = escalationLists?.errorImpact;
              const stage = escalationLists?.stage;
              const status = escalationLists?.status;
              const createdByName = escalationLists?.createdBy;
              const updatedByName = escalationLists?.updatedBy;
              const createdAt = escalationLists?.createdAt;
              const updatedAt = escalationLists?.updatedAt;

              let escalationReport = {
                Client: clientName,
                "Client SPOC Email": clientSpocEmail,
                Agent: agentName,
                Department: departmentName,
                Process: processName,
                "Reporting TL": reportingTlName,
                "Error Raise Date": moment(errorRaiseDate).format("YYYY-MM-DD"),
                "Transaction ID": transactionId,
                "Transaction Duplicate Reason": transactionDuplicateReason,
                "Transaction Date":
                  moment(transactionDate).format("YYYY-MM-DD"),
                "Error Description": errorDescription,
                "Error Investigation Remarks": errorInvestigationRemarks,
                "Error Impact": errorImpact,
                Stage: stage && stage,
                Status: status && status,
                //Auditor: auditorName,
                createdAt: createdAt,
                updatedAt: updatedAt,
              };
              escalationReports.push(escalationReport);
            }
          }
        );
      }  */
    },
  };
  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });

  {
    /*Escalation Complete and Delete action*/
  }
  const handleEscalationAction = (params) => {
    switch (params) {
      case "complete":
        APICaller(
          secureLocalStorage.getItem("role"),
          secureLocalStorage.getItem("accessToken"),
          "/escalations/updateMul",
          "POST",
          {
            role: secureLocalStorage.getItem("role"),
            ids: globalState.selectedRowKeysChildInfo,
          }
        )
          .then((response) => {
            getEscalationsList();
            setSelectedRowKeys([]);
          })
          .catch((error) => {});
        break;
      case "delete":
        let submitDeleteAuditFormModal = "";
        if (isDeleteModalVisible === true) {
          submitDeleteAuditFormModal = "clickedDeleteAuditOK";
        }
        if (submitDeleteAuditFormModal === "clickedDeleteAuditOK") {
          setShowLoader(true);
          APICaller(
            secureLocalStorage.getItem("role"),
            secureLocalStorage.getItem("accessToken"),
            "/escalations/deleteMul",
            "POST",
            {
              role: secureLocalStorage.getItem("role"),
              ids: globalState.selectedRowKeysChildInfo,
            }
          )
            .then((response) => {
              setIsDeleteModalVisible(false);
              getEscalationsList();
              setSelectedRowKeys([]);
            })
            .catch((error) => {});
        } else {
          setIsDeleteModalVisible(true);
        }
        break;
    }
  };
  useEffect(() => {
    getEscalationsList();
  }, []);

  return (
    <>
      <div className="all-content">
        <div className="d-flex">
          <BeforeTableHeader list={listItems} showListingCount="yes" href="" />

          <div className="right-buttons">
            {(secureLocalStorage.getItem("role") === "admin" ||
              secureLocalStorage.getItem("role") === "qc") && (
              <>
                {globalState.selectedRowKeysChildInfo &&
                  globalState.selectedRowKeysChildInfo.length > 0 && (
                    <JsonToExcel
                      title="Export as Excel"
                      data={globalState.exportEscalationReportJson}
                      fileName="escalationReport"
                      btnClassName="table-buttons"
                    />
                  )}
                {secureLocalStorage.getItem("role") === "admin" &&
                  globalState.selectedRowKeysChildInfo &&
                  globalState.selectedRowKeysChildInfo.length > 0 && (
                    <span
                      className="escalationDelete auditDelete table-buttons"
                      onClick={() => {
                        handleEscalationAction("delete");
                      }}
                    >
                      Delete
                    </span>
                  )}
                <span className="performAudit" onClick={showModal}>
                  <Link to="" style={{ color: "black" }}>
                    {" "}
                    + New Escalation
                  </Link>
                </span>
                <Modal
                  className="all-modal"
                  onCancel={handleCancel}
                  onOk={handleOk}
                  title={
                    <div className="headermodaltitle">
                      Create New Escalation
                    </div>
                  }
                  open={isModalOpen}
                  bodyStyle={{ height: "70vh", overflowY: "scroll" }}
                  footer={[
                    <Button
                      key="back"
                      className="submit-btn border-0 rounded"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>,
                    <Button
                      key="submit"
                      className="submit-btn border-0 rounded"
                      onClick={handleOk}
                    >
                      Create Escalation
                    </Button>,
                  ]}
                  width={"50vw"}
                >
                  {children}
                </Modal>
              </>
            )}
          </div>
        </div>

        <div className="all-table-holder">
          {showLoader === true && (
            <div className="customLoader">Loading...</div>
          )}

          <Table
            rowSelection={{ type: selectionType, ...rowSelection }}
            columns={columns}
            dataSource={globalState.list}
            pagination={globalState?.pagination}
            onChange={(e) => {
              getEscalationsList("pagination", e);
            }}
          />
        </div>
      </div>
      <DuplicateTransactionReasonForm
        open={visible}
        setVisible={setVisible}
        onCreate={onCreate}
      />
      <Modal
        title=""
        open={isModalVisible}
        onOk={handleOk}
        onCancel={() => {
          setIsModalVisible(false);
          setIsModalOpen(true);
        }}
        okText="OK"
        cancelText="Cancel"
        className="escalationModal"
      >
        Please review your selected or entered information carefully before
        submitting the form, as changes may not be possible once it's submitted.
      </Modal>

      <Modal
        title=""
        open={isDeleteModalVisible}
        onOk={() => {
          handleEscalationAction("delete");
        }}
        onCancel={() => {
          setIsDeleteModalVisible(false);
        }}
        okText="Yes"
        cancelText="No"
        className="escalationModal"
      >
        Are you sure you want to delete this escalation?
      </Modal>
    </>
  );
};
export default Escalations;
